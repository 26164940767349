<template>
  <div>
    <v-card>
      <div class="px-5">
        <card-title :is-image="false" icon="mdi-text-box"
          >TAT Processes</card-title
        >
      </div>

      <v-card-text>
        <div v-if="!isLoading && !configs.length" class="tw-my-3">
          <v-divider></v-divider>

          <div
            class="
              tw-flex
              tw-flex-col
              tw-items-center
              tw-justify-center
              tw-gap-3
              tw-py-16
            "
          >
            <v-icon x-large>mdi-text-box</v-icon>

            <div>
              <p class="c-gray-text mb-0">
                Currently, there are no vehicles Turnaround
              </p>
              <p class="c-gray-text mb-0">
                Time (TAT) for the work done.
                <span class="c-blue-text tw-font-semibold">Add a TAT</span>
              </p>
              <p class="c-blue-text mb-0 tw-font-semibold">
                process using the button at the bottom right
              </p>
            </div>
          </div>
        </div>

        <v-data-table
          v-else
          :headers="headers"
          :items="configs"
          :loading="isLoading"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              class="mr-2"
              color="secondary"
              @click="openDialog(true, item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-btn
      bottom
      class="secondary"
      dark
      fab
      fixed
      right
      id="createUserModalBtn"
      @click="openDialog(false, null)"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="dialog.open" width="400" v-if="dialog.open" persistent>
      <v-card>
        <v-card-title>
          <h3 class="tw-w-full tw-text-center">
            {{ dialog.isEditing ? 'Update' : 'Add' }} TAT Process
          </h3>
        </v-card-title>

        <v-card-text>
          <v-form v-model="dialog.form.isValid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Process"
                    :items="dialog.processes"
                    item-text="processId"
                    return-object
                    v-model="dialog.form.process"
                    :rules="[requiredObject('Process')]"
                    :disabled="dialog.isEditing"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Description"
                    v-model="dialog.form.process.processDescription"
                    :rules="[required('Description')]"
                    disabled
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="TAT Duration (Hrs)"
                    v-model="dialog.form.tat"
                    :rules="[required('Duration')]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-btn
                    class="tw-w-full"
                    color="secondary"
                    outlined
                    @click="closeDialog"
                    >Cancel</v-btn
                  >
                </v-col>

                <v-col cols="12" sm="6">
                  <v-btn
                    class="tw-w-full"
                    color="secondary"
                    :loading="dialog.form.isLoading"
                    :disabled="!dialog.form.isValid"
                    @click="submitForm()"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import axios from 'axios'

export default {
  components: {
    CardTitle,
  },
  data: () => ({
    headers: [
      {
        text: 'Process',
        align: 'start',
        sortable: false,
        value: 'processId',
      },
      {
        text: 'Description',
        align: 'start',
        sortable: false,
        value: 'processDescription',
      },
      {
        text: 'Duration (Hrs)',
        align: 'start',
        sortable: false,
        value: 'tat',
      },
      {
        text: 'Action',
        align: 'start',
        sortable: false,
        value: 'action',
      },
    ],
    isLoading: false,
    configs: [],
    dialog: {
      open: false,
      isEditing: false,
      processes: [],
      form: {
        isLoading: false,
        isValid: false,
        process: {
          processId: '',
          processDescription: '',
        },
        tat: '',
      },
    },
  }),
  methods: {
    async fetchConfigs() {
      this.isLoading = true

      await axios
        .get('/motor-assessment/api/tat-configs/all')
        .then(({ data }) => {
          if (data.success) this.configs = data.data
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.message ?? 'Failed to fetch TAT configs',
            variant: 'error',
          })
        })
        .finally(() => (this.isLoading = false))
    },
    async fetchProcesses() {
      await axios
        .get('/motor-assessment/api/tat-configs/processes')
        .then(({ data }) => {
          if (data.success) this.dialog.processes = data.data
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.message ?? 'Failed to fetch TAT processes',
            variant: 'error',
          })
        })
    },
    openDialog(isEditing, selectedItem) {
      this.fetchProcesses()
      this.dialog.open = true
      this.dialog.isEditing = isEditing
      if (selectedItem) {
        this.dialog.form.process.processId = selectedItem.processId
        this.dialog.form.process.processDescription =
          selectedItem.processDescription
        this.dialog.form.tat = selectedItem.tat
      }
    },
    closeDialog() {
      this.dialog = {
        open: false,
        isEditing: false,
        processes: [],
        form: {
          isLoading: false,
          isValid: false,
          process: {
            processId: '',
            processDescription: '',
          },
          tat: '',
        },
      }
    },
    requiredObject(field) {
      return (v) => (v && !!v['processId']) || `${field} is required`
    },
    required(field) {
      return (v) => !!v || `${field} is required`
    },
    async submitForm() {
      this.dialog.form.isLoading = true

      const method = this.dialog.isEditing ? 'put' : 'post'

      const url = this.dialog.isEditing
        ? '/motor-assessment/api/tat-configs/update'
        : '/motor-assessment/api/tat-configs/create'

      const payload = {
        processId: this.dialog.form.process.processId,
        processDescription: this.dialog.form.process.processDescription,
        tat: Number(this.dialog.form.tat),
      }

      await axios({
        method,
        url,
        data: payload,
      })
        .then(async ({ data }) => {
          if (data.success) {
            this.$store.commit('SNACKBAR', {
              state: 'success',
              message:
                data.msg ??
                ` TAT config ${
                  this.dialog.isEditing ? 'updated' : 'added'
                } successfully`,
              variant: 'success',
            })

            await this.fetchConfigs()
            this.closeDialog()
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message:
              err.message ??
              `Failed to ${
                this.dialog.isEditing ? 'update' : 'add'
              } TAT config`,
            variant: 'error',
          })
        })
        .finally(() => (this.dialog.form.isLoading = false))
    },
    // },
  },
  beforeMount() {
    this.fetchConfigs()
  },
}
</script>
